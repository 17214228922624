import { DocumentFieldType } from '../document-field';

export const propertyMeta: DocumentFieldType = {
  _type: 'Map',
  formStates: {
    _type: 'Map',
    RSAA: {
      _type: 'Map',
      instances: {
        _type: 'Array',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          signing: {
            _type: 'Map',
            parties: {
              _type: 'Array',
              _children: {
                _type: 'Map',
                id: { _type: 'UUID', _required: true },
                snapshot: {
                  _type: 'Map',
                  email: { _type: 'string', _subtype: 'email' }
                }
              }
            },
            useSigningOrder: {
              _type: 'boolean'
            },
            signingOrderSettings: {
              _type: 'Array',
              _children: {
                _type: 'Map',
                id: { _type: 'UUID', _required: true },
                type: { _type: 'number' },
                order: { _type: 'number' },
                auto: { _type: 'boolean' }
              }
            }
          }
        }
      }
    },
    '*': {
      _type: 'Map',
      instances: {
        _type: 'Array',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          cover: {
            _type: 'Map',
            mode: { _type: 'number' },
            custom: {
              _type: 'Map'
            }
          }
        }
      }
    }
  },
  offerManagement: {
    _type: 'Map',
    prospectivePurchasers: {
      _type: 'Map'
    },
    subDocumentsAgencyCanAccessAndEdit: {
      _type: 'Array',
      _children: {
        _type: 'Map',
        ydocReference: { _type: 'string' }
      }
    },
    submittedOffers: {
      _type: 'Array'
    }
  }
};
