import { PDFDocument, EncryptedPDFError } from 'pdf-lib';

export const processPdf = async (
  pdfBytes: Uint8Array | ArrayBuffer,
  preFlatProcess?: (doc: PDFDocument) => Promise<void>,
): Promise<{ pdf?: Uint8Array, isEncrypted: boolean } | undefined> => {
  if (pdfBytes.byteLength === 0) return undefined;

  try {
    const pdf = await PDFDocument.load(pdfBytes);
    if (preFlatProcess) {
      await preFlatProcess(pdf);
    }
    pdf.getForm().flatten();

    return { pdf: await pdf.save(), isEncrypted: false };
  } catch (e) {
    if (e instanceof EncryptedPDFError) {
      return { isEncrypted: true };
    }

    return undefined;
  }
};
